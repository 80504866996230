import {
  ColorType,
  CompilationPhoto,
  GetCompilationPhotosResponse,
  GetCompilationPhotosResponseBack,
} from "../slices/apiSliceTypes";

const colors: ColorType[] = [
  "#E4938F",
  "#A29060",
  "#FFBE3C",
  "#7E265A",
  "#FF6E00",
  "#74A3B7",
];

const get_color = (index: number): ColorType => {
  return colors[index % colors.length];
};

export const image_date_sort = (
  image_1: Pick<CompilationPhoto, "name" | "date">,
  image_2: Pick<CompilationPhoto, "name" | "date">
) => {
  if (image_1.date == null) return -1;
  if (image_2.date == null) return 1;
  if (image_1.date < image_2.date) return -1;
  else if (image_1.date > image_2.date) return 1;
  else {
    if (image_1.name < image_2.name) return -1;
    else return 1;
  }
};

export const parseMyCompilationsList = (list: any[]): any[] => {
  return list.map((item, index) => ({
    compil_id: item.compil_id,
    compil_name: item.compil_name,
    customer_id: item.customer_id,
    color: get_color(index),
    thumbnail: item.thumbnail,
  }));
};

export const parseSharedCompilationsList = (list: any[]): any[] => {
  return list.map((item, index) => ({
    compil_id: item.compil_id,
    compil_name: item.compil_name,
    sender_id: item.sender_id,
    color: get_color(index),
    thumbnail: item.thumbnail,
  }));
};

export const get_capture_time = (exif_date: string) => {
  return exif_date.split(" ")[1];
};

export function get_sort_number(exif_datetime: string | null) {
  if (exif_datetime == null) return Number.MAX_SAFE_INTEGER;
  const captureDate = exif_datetime.split(" ")[0].split(":");
  const captureDays =
    Number(captureDate[0]) * 366 +
    Number(captureDate[1]) * 31 +
    Number(captureDate[2]);

  const captureTime = get_capture_time(exif_datetime).split(":");
  const captureSeconds =
    Number(captureTime[0]) * 3600 +
    Number(captureTime[1]) * 60 +
    Number(captureTime[2]);

  const date = captureDays * 60 * 60 * 24 + captureSeconds;
  return date;
}

export const parseCompilationPhotos = (
  response: GetCompilationPhotosResponseBack
): GetCompilationPhotosResponse => {
  return {
    total_photos: response.total_photos,
    photos: response.photos.map((item) => ({
      name: item.name,
      display_link: item.display_link,
      date: get_sort_number(item.exif_datetime),
    })),
  };
};

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  parseCompilationPhotos,
  parseMyCompilationsList,
  parseSharedCompilationsList,
} from "../Utils/ResponseParsingUtils";
import {
  LoginQueryArgs,
  LoginQueryResponse,
  RegisterQueryArgs,
  RegisterQueryResponse,
  RemoveCompilationPhotoArgs,
  ShareCompilationArgs,
  UnlockQueryArgs,
  UnlockQueryResponse,
  GetCompilationPhotosArgs,
  GetCompilationPhotosResponse,
  GetCompilationsReceivedResponse,
  GetCompilationsReceivedArgs,
  GetMyCompilationsResponse,
  GetMyCompilationsArgs,
  GetUserNameArgs,
  GetUserNameResponse,
  GetCompilationInfoResponse,
  GetCompilationInfoArgs,
  GetCompilationPhotosResponseBack,
} from "./apiSliceTypes";

const environment = process.env.REACT_APP_ENVIRONMENT;

const backendLinks = {
  development: "http://127.0.0.1:8787",
  staging:
    "https://backend.main-bvxea6i-kvt6irimxsm5a.ovhcloud-fr-1.webpaas.ovh.net",
  production: "https://backend.mews.wanderstory.co",
};

const backendLink = backendLinks[environment] ?? backendLinks["production"];

// Define a service using a base URL and expected endpoints
export const apiSlice = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: backendLink,
    prepareHeaders: (headers) => {
      headers.set("Accept", "application/json");
      headers.set("User-Agent", "Alexandru Birtoiu");
      return headers;
    },
    mode: "cors",
    method: "POST",
  }),
  reducerPath: "api",
  tagTypes: [
    "Years",
    "Months",
    "Folders",
    "Photos",
    "Tree",
    "Photo Count",
    "Compilations",
    "Tags",
  ],
  endpoints: (build) => ({
    login: build.mutation<LoginQueryResponse, LoginQueryArgs>({
      query: ({ email, pw }) => {
        return {
          url: `/login`,
          body: {
            email: email,
            pw: pw,
          },
        };
      },
    }),

    register: build.mutation<RegisterQueryResponse, RegisterQueryArgs>({
      query: ({ email, pw, name }) => {
        return {
          url: `/register`,
          body: {
            email: email,
            pw: pw,
            name: name,
          },
        };
      },
    }),

    getUserName: build.query<GetUserNameResponse, GetUserNameArgs>({
      query: ({ customerId }) => ({
        url: `/get_user_name`,
        body: {
          customerId: customerId,
        },
        method: "POST",
      }),
    }),

    getMyCompilations: build.query<
      GetMyCompilationsResponse,
      GetMyCompilationsArgs
    >({
      query: ({ customerId }) => ({
        url: `/compilations/my-compilations`,
        body: {
          customerId: customerId,
        },
      }),
      transformResponse: (response: any) => {
        return parseMyCompilationsList(response);
      },
      providesTags: ["Compilations"],
    }),

    getCompilationsReceived: build.query<
      GetCompilationsReceivedResponse,
      GetCompilationsReceivedArgs
    >({
      query: ({ customerId }) => ({
        url: `/compilations/compilations-received`,
        body: {
          customerId: customerId,
        },
      }),
      transformResponse: (response: any) => {
        return parseSharedCompilationsList(response);
      },
      providesTags: ["Compilations"],
    }),

    removeCompilationPhoto: build.mutation<void, RemoveCompilationPhotoArgs>({
      query: ({ compilationId, photosPaths }) => ({
        url: `/compilations/remove`,
        body: {
          compilId: compilationId,
          photosToRemove: photosPaths,
        },
      }),
      invalidatesTags: (result, error, arg) => {
        return ["Compilations"];
      },
    }),

    shareCompilation: build.mutation<void, ShareCompilationArgs>({
      query: ({ customerId, compilationId, orderStatus }) => ({
        url: `/print/upload`,
        body: {
          customerId: customerId,
          compilId: compilationId,
          orderStatus: orderStatus,
        },
      }),
    }),

    unlock: build.mutation<UnlockQueryResponse, UnlockQueryArgs>({
      query: ({ senderId, email, pw, compilId, createIfNotExists }) => ({
        url: `/unlock`,
        body: {
          sender_id: senderId,
          email: email,
          pw: pw,
          compil_id: compilId,
          create_if_not_exists: createIfNotExists,
        },
      }),
    }),

    getCompilationInfo: build.query<
      GetCompilationInfoResponse,
      GetCompilationInfoArgs
    >({
      query: ({ compilId }) => ({
        url: `/compilations/compilation-info`,
        body: {
          compil_id: compilId,
        },
        method: "POST",
      }),
    }),

    getCompilationPhotos: build.query<
      GetCompilationPhotosResponse,
      GetCompilationPhotosArgs
    >({
      query: ({ customerId, compilId, limit }) => ({
        url: `/compilations/photos`,
        body: {
          customerId: customerId,
          compilId: compilId,
          limit: limit ? limit : null, // Include the limit parameter
        },
        method: "POST",
      }),
      transformResponse: (response: GetCompilationPhotosResponseBack) => {
        return parseCompilationPhotos(response);
      },
    }),
  }),
});

export const {
  useShareCompilationMutation,
  useRemoveCompilationPhotoMutation,
  useLoginMutation,
  useRegisterMutation,
  useUnlockMutation,
  useGetCompilationPhotosQuery,
  useGetMyCompilationsQuery,
  useGetCompilationsReceivedQuery,
  useGetUserNameQuery,
  useGetCompilationInfoQuery,
} = apiSlice;
